import React from 'react'
import cx from 'classnames'

import styles from './Stages.module.scss'

const STAGE_BG_COLOR_MAP = [
  'bg-primary-000',
  'bg-primary-100',
  'bg-primary-200',
]

export const Stages = ({ className, children }) => {
  return <div className={cx(styles.container, className)}>{children}</div>
}

Stages.Stage = ({ className, title, subtitle, index, isLast }) => {
  const Wrapper = ({ children }) =>
    isLast ? (
      <>
        <div className={styles.stageLastInnerContainer}>{children}</div>
        <span className={styles.stageLastArrow} />
      </>
    ) : (
      children
    )

  return (
    <div
      className={cx(className, styles.stage, STAGE_BG_COLOR_MAP[index], {
        [styles.stageFirst]: index === 0,
        [styles.stageLast]: isLast,
      })}
    >
      <Wrapper>
        <p className={styles.stageTitle}>{title}</p>
        <p className={styles.stageSubtitle}>{subtitle}</p>
      </Wrapper>
    </div>
  )
}
