import React from 'react'

const BackArrowIcon = ({ className = '', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      className={`fill-current ${className}`}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12 5.25H2.888l4.2-4.2L6 0 0 6l6 6 1.05-1.05-4.162-4.2H12z"
      ></path>
    </svg>
  )
}

export default BackArrowIcon
