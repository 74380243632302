import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS } from '../../../config/urls'

import { ServicesSectionImg } from '../shared/ServicesSectionImg'
import { ServicesLayout } from '../shared/ServicesLayout'
import { ServicesHeader } from '../shared/ServicesHeader'
import { ServicesChecklist } from '../shared/ServicesChecklist'
import { ServicesSectionText } from '../shared/ServicesSectionText'
import { ServicesListCard } from '../shared/ServicesListCard'
import { Card } from 'components/common/Card'
import { Stages } from 'components/common/Stages'
import StarIcon from '../../svg/StarIcon'
import { Question } from '../../common/Question'

import GynecologyHeaderImg from 'components/images/gynecology-hero.png'
import BirthControlImg from 'components/images/gynecology-img.png'

// TODO: Update copy
const GYNECOLOGY_SERVICES_LIST = [
  'Well Woman Exam',
  'Contraception options',
  'Sexually Transmitted Infections',
  'Hormonal Management',
  <>
    Menstrual issues{' '}
    <span className="text-xs italic">such as pain, bleeding or infrequent menses</span>
  </>,
  <>
    Iron Deficiency Anemia{' '}
    <span className="text-xs italic">
      due to heavy menses
    </span>
  </>,
  'Breast health',
  <>
    Ectopic Pregnancy{' '}
    <span className="text-xs italic">with knowledge of immediate medical treatment</span>
  </>,
  'Infertility Evaluation & Assisted Fertility Treatment',
  'Egg preservation counseling',
  'Fibroids and uterine dysfunction',
  'Polycystic Ovarian Syndrome (PCOS)',
  'Ovarian Cysts',
  'Premenstrual Tension (PMS)',
  'Abnormal Pap Smear management (Colposcopy, Cryotherapy)',
  <>
    Hysteroscopic out-patient surgeries (Endometrial Ablation){' '}
    <span className="text-xs italic">
      for heavy menses
    </span>
  </>,
  <>
    Minimally-invasive, same-day surgeries (Laparoscopic){' '}
    <span className="text-xs italic">for Hysterectomies, Ovarian Cysts, Benign and Cancerous surgeries</span>
  </>,
  'Medical & Surgical Management of Endometriosis',
  'Pelvic pain',
  <>
    Pelvic Floor Dysfunction{' '}
    <span className="text-xs italic">such as urinary incontinence</span>
  </>,
  'Urinary Tract Infections (UTI)',
  'Hereditary Cancer Risk Evaluation and Treatment',
  'Bone Density Issues'
]

export const GynecologyServices = () => {
  return (
    <ServicesLayout>
      <ServicesHeader
        title="Gynecology"
        description="We provide preventative, diagnostic and specialized gynecologic services for women."
        image={
          <ServicesSectionImg
            src={GynecologyHeaderImg}
            alt="Flowers"
            direction="right"
            customStyles={{ container: 'mt-10 md-mt-0' }}
          />
        }
      />
      <section className="flex flex-wrap w-full justify-between mb-12 md-mb-12">
        <div className="mb-8 md-pr-16 w-full md-w-1/2">
          <ServicesSectionImg
            src={BirthControlImg}
            alt="Birth control"
            direction="left"
            customStyles={{ container: 'hidden md-block w-full md-w-full mb-20' }}
          />
        </div>
        <div className="w-full md-w-1/2 md-pl-12">
          <ServicesSectionText title="Providing a comfortable environment." className="md-w-full mb-20">
            <div className="flex flex-col space-y-4">
              <p>
                When you enter our practice, our hope is that you will feel comfortable enough to ask our staff any questions you may have regarding your health.
              </p>
              <p>
                With decades of experience, our staff strive to create a caring environment for patients.  Beyond reproductive health care, we always do our best to help with our patients’ overall health.
              </p>
              <p>
                Whether you are coming in for your annual check up or because there are areas of concern, we plan to be there for you every step of the way.
              </p>
            </div>
          </ServicesSectionText>
        </div>
      </section>

      <ServicesListCard
        className="bg-primary-300 text-white mb-12 md-mb-24"
        title="Our Gynecologic Services"
        items={GYNECOLOGY_SERVICES_LIST}
        icon={<StarIcon style={{ transform: 'translateY(1px)' }} />}
      />
      <div className="mb-40">
        <Question title="When do I start my pap smear?">
          Age 21. However if you have been sexually active you need to have an exam to check for infections.
        </Question>

        <Question title="What should I do if I forgot to take a pill?">
          In general, you can "make up" the pill you missed the next day along with the pill you are supposed to take. However if you missed 2 days in a row, you will need to take extra pills for 2 days in a row. But your period may start. If you miss more than 2 days, take extra precautions because you may be at risk for pregnancy.
        </Question>

        <Question title="I skip periods. Do I need to be checked?">
          Yes. You may have a hormonal imbalance that can be diagnosed and properly treated.
        </Question>

        <Question title="Are there any vitamins or supplements I should take?">
          Yes. In general, our diets are not adequate enough to provide prevention against common issues such as anemia (from bleeding each month even if a woman is on the pill) which affect 50% of women in the world. This can be prevented easily by taking Prenatal Vitamins from age 13 (when menses start) to age 55(menopause is over). There are several brands but taking a Gluten Free and easily available ones are the best.
        </Question>

        <Question title="I have very heavy bleeding. Should I be concerned?">
          Yes. Some girls and women need blood transfusions from bleeding too much and too fast. Properly diagnosed and treatment is the key.
        </Question>

        <Question title="I have moodiness and personality changes before my periods. Is there something I can do?">
          Yes. You may be suffering from Premenstrual Tension Syndrome or "PMS" and it is common. There are available treatments to improve it.
        </Question>

        <Question title="How often should I get my pap smears?">
          We recommend pap smears start at age 21 unless there are special circumstances. It can be repeated every 2 to 3 years if normal. However, the annual examination is strongly urged.
        </Question>
      </div>
    </ServicesLayout>
  )
}
