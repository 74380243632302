import React from 'react'
import cx from 'classnames'

import { Card } from '../../../common/Card'

import styles from './ServicesListCard.module.scss'

export const ServicesListCard = ({ className, title, items, icon }) => {
  return (
    <Card component="section" className={cx(styles.card, className)}>
      <div className={styles.timeline}>
        <div className={styles.timelineStart}>
          {icon}
        </div>
        <div className={styles.timelineLine} />
      </div>

      <div>
        <p className={styles.title}>{title}</p>

        <ul className={styles.list}>
          {items.map(item => (
            <li className={styles.listItem}>{item}</li>
          ))}
        </ul>
      </div>
    </Card>
  )
}
