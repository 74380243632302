import React from 'react'

const StarIcon = ({ className, ...props }) => {
  return (
    <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" className={`fill-current ${className}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-165.000000, -1233.000000)" fill="#FFFFFF">
          <path d="M182.999456,1239.12122 L181.420744,1236.37878 L175.582322,1239.75757 L175.582322,1233 L172.417678,1233 L172.417678,1239.75757 L166.579256,1236.37878 L165,1239.12122 L170.839905,1242.5 L165.000445,1245.87878 L166.579207,1248.62122 L172.417678,1245.24243 L172.417678,1252 L175.582322,1252 L175.582322,1245.24243 L181.420694,1248.62122 L183,1245.87878 L177.160095,1242.5 L182.999456,1239.12122 L182.999456,1239.12122 Z M181.920313,1246.16764 L181.130981,1247.54643 L174.791161,1243.88542 L174.791161,1251.20833 L173.208839,1251.20833 L173.208839,1243.88542 L166.869019,1247.54688 L166.079391,1246.1721 L172.419953,1242.50089 L166.079589,1238.83166 L166.868969,1237.45322 L173.208839,1241.11458 L173.208839,1233.79167 L174.791161,1233.79167 L174.791161,1241.11458 L181.130981,1237.45312 L181.920609,1238.8279 L175.580047,1242.49822 L181.920313,1246.16764 L181.920313,1246.16764 Z"></path>
        </g>
      </g>
    </svg>
  )
}

export default StarIcon
