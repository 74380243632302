import React from 'react'
import cx from 'classnames'

import styles from './ServicesSectionText.module.scss'

export const ServicesSectionText = ({ title, children, className }) => {
  return (
    <div className={cx(styles.sectionTextContainer, className)}>
      <div className={styles.sectionBorderAccent} />
      <h2 className={styles.sectionTitle}>{title}</h2>
      <div className={styles.sectionDescriptionText}>{children}</div>
    </div>
  )
}
