import React from 'react'
import cx from 'classnames'

import CheckIcon from '../../../svg/CheckIcon'

import styles from './ServicesChecklist.module.scss'

export const ServicesChecklist = ({ className, items }) => {
  return (
    <ul className={cx(className, styles.approvedItems)}>
      {items.map(item => (
        <li className={styles.approvedItem}>
          <CheckIcon className={styles.approvedItemCheck} />
          {item}
        </li>
      ))}
    </ul>
  )
}
