import React from 'react'

import Layout from '../../components/layout'
import { GynecologyServices } from '../../components/Services/Gynecology'

const GynecologyPage = () => {
  return (
    <Layout seo={{ title: 'Gynecology' }}>
      <GynecologyServices />
    </Layout>
  )
}

export default GynecologyPage
