import React from 'react'
import cx from 'classnames'

import styles from './ServicesSectionImg.module.scss'

export const ServicesSectionImg = ({
  direction,
  className,
  customStyles = {},
  ...props
}) => {
  return (
    <div
      className={cx(customStyles.container, {
        [styles.sectionImageContainerDirectionRight]: direction === 'right',
        [styles.sectionImageContainerDirectionLeft]: direction === 'left',
      })}
    >
      <div
        className={cx(styles.sectionImageInnerContainer, customStyles.inner)}
      >
        <img className={cx(styles.sectionImage, customStyles.img)} {...props} />
      </div>
    </div>
  )
}
