import React from 'react'
import cx from 'classnames'

import styles from './Card.module.scss'

export const Card = ({ className, component, children, ...props }) => {
  const Component = component || 'div'

  return (
    <Component className={cx(styles.card, className)} {...props}>
      {children}
    </Component>
  )
}

Card.Header = ({ className, component, children, ...props }) => {
  const Component = component || 'p'

  return (
    <Component className={cx(styles.header, className)} {...props}>
      {children}
    </Component>
  )
}

Card.Subheader = ({ className, component, children, ...props }) => {
  const Component = component || 'p'

  return (
    <Component className={cx(styles.subheader, className)} {...props}>
      {children}
    </Component>
  )
}
