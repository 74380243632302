import React from 'react'
import cx from 'classnames'

import styles from './Question.module.scss'

export const Question = ({ className, title, children, ...props }) => {
  return (
    <div className={cx(styles.questionCard, className)} {...props}>
      <div className={styles.footerContent}>
        <h3 className={styles.questionTitle}>
          {title}
        </h3>
        <div className={styles.questionAnswer}>
          {children}
        </div>
      </div>
    </div>
  );
}
