import React from 'react'

const CheckIcon = ({ className, ...props }) => {
  return (
    <svg
      width="13"
      height="11"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
      {...props}
    >
      <path
        d="M12.924982 1.30877376L11.2089445.0687321C11.1558067.02863838 11.0901659 0 11.0151479 0c-.0750181 0-.1437846.02863838-.1969224.07159594L4.57610002 7.4202031S2.12238519 5.2580057 2.05361866 5.1950013c-.06876653-.0630044-.15941332-.1689664-.29694638-.1689664s-.20004809.088779-.27194037.1546472c-.05313778.0515491-.92834816.8935173-1.35970185 1.3116376-.02500602.0257745-.04063477.0400937-.06251503.0601406C.025006 6.6011455 0 6.6555584 0 6.715699c0 .0630045.02500601.1145535.06251503.1632388l.08752104.0744598s4.35417167 3.8318146 4.42606395 3.8976828C4.64799231 10.9169487 4.73551334 11 4.86366915 11c.12503006 0 .22817985-.123145.28756913-.1775579L12.937485 1.65816194C12.974994 1.6094767 13 1.55506379 13 1.49205936c0-.07159594-.0312575-.13173653-.075018-.1832856z"
        fill="#76CB7E"
        fill-rule="evenodd"
      />
    </svg>
  )
}

export default CheckIcon
