import React from 'react'
import Link from 'gatsby-link'

import { INTERNAL_URLS } from '../../../../config/urls'
import BackArrowIcon from '../../../svg/BackArrowIcon'

export const ServicesHeader = ({ title, description, image, additionalContent }) => {
  return (
    <header className="flex w-full flex-wrap pt-20 pb-24 justify-between items-center">
      <div className="w-full md-w-1/2 md-pr-4">
        <Link
          to={INTERNAL_URLS.SERVICES}
          className="inline-block mb-6 text-primary-300 font-semibold"
        >
          <BackArrowIcon
            className="relative inline w-3 mr-3 top-0 -mt-1"
            width="12"
            height="12"
          />
          View All Services
        </Link>
        <h1 className="text-2xl md-text-4xl mb-4 md-mb-6 font-bold">{title}</h1>
        <p className="text-md md-text-2xl text-gray-800">{description}</p>
      </div>
      {image}
    </header>
  )
}
